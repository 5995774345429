<template>
  <component
    :is="char.empty ? 'span' : 'div'"
    v-if="char"
    :id="name"
    ref="el"
    :class="{ 'relative inline-block w-fit scale-0': !char.empty }"
    :style="{
      marginLeft: char.space + 'em',
      zIndex: char.zIndex ?? 0,
    }"
  >
    {{ char.empty ? " " : char.value }}
  </component>
  <div v-else :id="name" class="relative inline-block w-fit scale-0">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  char?: {
    value?: string;
    empty: boolean;
    space?: number;
    zIndex?: number;
  };
  delay?: number;
  speed?: number;
  name?: string;
  init?: boolean;
  playOnce?: boolean;
}>();

const el = ref<HTMLDivElement | HTMLSpanElement>();

const { char, delay, speed, name, playOnce } = toRefs(props);

const variants = computed(() => ({
  initial: {
    scale: playOnce.value ? 1 : 0,
  },
  visible: {
    scale: 1,
    transition: {
      delay: (speed.value ?? 0) + (delay.value ?? 0),
      type: "spring",
      stiffness: 500,
      damping: 30,
      mass: 3,
    },
  },
}));
useMotion(el, variants);
</script>
